import React, { useRef,useEffect  } from "react";
import {View,TextInput,StyleSheet,TouchableOpacity,Image,Text} from "react-native";
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import Ionicons from '@expo/vector-icons/Ionicons';


export default function ImagePicker(props) {

    const {set,value} = props;

    const inputFile = useRef(null) 
    


    const onButtonClick = () => {
     inputFile.current.click();
    };

    function handleChange(event) {
      if (event.target.value.length == 0) return null

          if(event.target.files[0].type.split('/')[0] === 'image'){
              Compress(URL.createObjectURL(event.target.files[0])) 
          }else{
            set(event.target.files[0])
          }

    }

    const Compress = async (uri) => {
      const manipResult = await manipulateAsync(
          uri,
          [],
          { compress: 0.7, format: SaveFormat.PNG }
    );
    set(manipResult.uri)
  };

  const Logo = () => {

    if(!value){

      return (
        <View style={styles.nologo}>
            <Ionicons name={'camera'} color={'#ccc'} size={25} />
        </View>
      )

    }

    if(value.name){
        return (
            <View style={{flexDirection:'row',paddingVertical:10}}>
                <View style={styles.tinyDoc}>
                    <Ionicons name={'document-text'} color={'#aaa'} size={18} />
                </View>
                <Text>{value.name}</Text>
            </View>
        )
    }

    return (
      <View style={{flexDirection:'row',paddingVertical:10}}>
        <Image style={styles.tinyLogo} source={{ uri: value}}/>
            <View style={{position:'absolute',backgroundColor:'#777',right:0,bottom:0,opacity:0.8,padding:2}}>
            <Ionicons name={'pencil'} color={'#fff'} size={10} />
            </View>
      </View>
    )
  }



    return (
        <View style={styles.container} {...props}>
            <input type='file' id='file' ref={inputFile}  style={{display: 'none'}}  onChange={handleChange}/>
            <TouchableOpacity onPress={onButtonClick} >
                <Logo/>
            </TouchableOpacity>
        </View>
    );

  }


    const styles = StyleSheet.create({
      container:{
        flexDirection:'row',
      },
      btnAdd:{
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      tinyLogo:{
        width:50,
        height:50,
        backgroundColor: 'transparent',
      },
      tinyDoc:{
        width:20,
        height:20,
        backgroundColor: 'transparent'
      },
      nologo:{
        borderWidth:1,
        borderColor:'#eee',
        borderRadius:5,
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center',
        width:50,
        height:50,
      }
    
});

  