import React, { useState,useEffect } from 'react';
import {SafeAreaView,View,StyleSheet,Image,Text,Linking} from 'react-native';
 
import {DrawerContentScrollView,DrawerItemList,DrawerItem,} from '@react-navigation/drawer';
import {getAuth,signOut} from 'firebase/auth';
import Storage from '../assets/js/storage';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MaterialCommunityIcons,Ionicons } from '@expo/vector-icons';


function Logout(){
  const auth = getAuth();
    signOut(auth).then(() => {
      Storage.RemoveAll()
      console.log("signout")
    }).catch((error) => {
      console.log(error)
    });
}




const SubMenu = (data) => {
  const SubMenu = data.sub
  const ScreenName = data.state.routeNames[data.state.index]
  const Screen = SubMenu.some((e) => e.nav === ScreenName)
  const [expand, setExpands] = useState(false);

  useEffect(() => {
    setExpands(Screen)

  }, [data]);

  const SubItem = () => {
      return  (
        <View style={{marginHorizontal:20}}>
            {SubMenu.map((key) => {
              const Selected = ScreenName == key.nav && {color:'#4581b3',fontWeight:'400'}
            return <TouchableOpacity key={key.name} onPress={()=>data.navigation.navigate(key.nav)} style={styles.subtxt}><Text style={[styles.txt,Selected]}>{key.name}</Text></TouchableOpacity>; 
            })}
        </View>
      )
  }


  return (
    <View>
      <DrawerItem
          label={data.label}
          onPress={() => setExpands(!expand)}
          labelStyle={{color: '#aaa'}}
          icon={({ focused, color, size }) => {
            return <MaterialCommunityIcons name={data.icon} color={'#aaa'} size={20} />;
          }}
        />
        {expand?<SubItem/>:null}
    </View>
  )
}

const Email = (data) => {
  if(!data) return null
  return (
    <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
      <Ionicons name={'person-circle'} color={'#aaa'} size={18} />
      <Text style={{fontSize:12,color:'#aaa',marginLeft:5}}>{data.email}</Text>
    </View>
  )
}
 
const CustomSidebarMenu = (props) => {

  return (
    <SafeAreaView style={{flex: 1}}>
      {/*Top Large Image */}
      <View style={{padding:10}}>
      <Image
        source={require('../assets/images/logo_donkeez.png')}
        style={styles.sideMenuProfileIcon}
      />
      {Email(props.user)}

      </View>
      <DrawerContentScrollView {...props}>
      <SubMenu {...props} sub={[{name:'Accounts',nav:'account'},{name:'Statement',nav:'statement'}]} label={'Accounts'} icon={'wallet'}/>
        <DrawerItemList {...props} />
        <SubMenu {...props} sub={[{name:'Products',nav:'product'},{name:'Costing',nav:'costing'}]} label={'Products'} icon={'label-multiple'}/>
        <SubMenu {...props} sub={[{name:'Orders',nav:'orders'},{name:'Ecommerce',nav:'order_ecommerce'},{name:'Quotation',nav:'order_quotation'}]} label={'Orders'} icon={'receipt'}/>
        <SubMenu {...props} sub={[{name:'staff',nav:'staff'},{name:'claims',nav:'claims'},{name:'payroll',nav:'payroll'}]} label={'Staff'} icon={'account-group'}/>
        <SubMenu {...props} sub={[{name:'Ledger',nav:'Ledger'},{name:'Exspenses',nav:'Exspenses'}]} label={'Reports'} icon={'chart-areaspline-variant'}/>
        <SubMenu {...props} sub={[{name:'Partner',nav:'transporters'},{name:'Shipment',nav:'transporter_shipment'},{name:'Payment',nav:'transporter_payments'}]} label={'Transporter'} icon={'truck-delivery'}/>
        <DrawerItem
          label="Logout"
          onPress={() => Logout()}
          labelStyle={{color: '#aaa'}}
          icon={({ focused, color, size }) => {
            return <MaterialCommunityIcons name={'logout'} color={'#aaa'} size={20} />;
          }}
        />
      </DrawerContentScrollView>

    </SafeAreaView>
  );
};
 
const styles = StyleSheet.create({
  sideMenuProfileIcon: {
    resizeMode: 'center',
    width: 130,
    height: 60,
    alignSelf: 'center',
  },
  iconStyle: {
    width: 15,
    height: 15,
    marginHorizontal: 5,
  },
  customItem: {
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  txt:{
    color:'#aaa',
    fontSize:14,
    fontWeight:'300',
    textTransform: 'capitalize',
  },
  subtxt:{
    padding:5,
    borderRadius:4,
    margin:5
  }
});
 
export default CustomSidebarMenu;