import React from "react";
import { Page, Text, Image, Document, StyleSheet,View,Font} from "@react-pdf/renderer";
import Logo from "./img/logo.png";
import moment from 'moment';
import Helvetica from "../../../assets/fonts/Helvetica.ttf";

export default function PdfReceipt(props) {

  const {details,user} = props
  console.log(user)

  Font.register({ family: 'Helvetica', src: Helvetica });

  const CompanyAddress = () => {
    return  (
      <View style={styles.address}>
              <Text style={{color:'#111',fontWeight:'700',fontSize:12}}>{user.name}</Text>
              <Text style={styles.stext}>({user.ssm})</Text>
              <View style={{flexDirection:'row',flexShrink: 1,textAlign:'left',width:122}}> 
                <Text style={[styles.stext,{flex: 1,textAlign:'left'}]}>{user.address}</Text>
              </View>
              {Additional('website',user.website)}
              {Additional('Tel',user.tel)}
      </View>
    )
  }

  const Additional = (val,data) => {
    if(!data) return null
    return <Text style={{color:'#111',fontSize:8}}>{val} : {data}</Text>
  }

  const QRBOX = () => {
    return (
      <View style={styles.headerbox}>
          <Text style={styles.boldtxt}>RECEIPT</Text>
          <View style={styles.center}><Text style={styles.boldtxt}>NO : </Text><Text style={[styles.boldtxt,{color:'red'}]}>#{details.id}</Text> 
          </View>
      </View>
    )
  }

  const LOGO = () => {
    return (
      <View style={styles.headerbox}>
        <Image style={{flex:1}} source={{ uri: user.logo}} />
      </View>
    )
  }

  const BillDetails = () => {
    return(
      <View style={{flexDirection:'row',marginRight:10}}>
        <View style={{flex:2}}>
          <Text style={styles.text}>Invoice Number</Text>
          <Text style={styles.text}>Received From</Text>
          <Text style={styles.text}>Paid Date</Text>
          <Text style={styles.text}>Payment Terms</Text>
        </View>
        <View style={{flex:6}}>
          <Text style={styles.text}>: #{details.orderid}</Text>
          <Text style={styles.text}>: {details.name}</Text>
          <Text style={styles.text}>: {moment(details.date.toDate()).format('DD-MM-YYYY')}</Text>
          <Text style={styles.text}>: {details.payment_method}</Text>
        </View>
      </View>
    )
  }

  const TableTh = () => {
    return(
      <View style={{flexDirection:'row',borderBottomWidth:0.5,borderTopWidth:0.5,paddingVertical:2,paddingHorizontal:4,marginTop:15}}>
        <View style={{flex:6}}><Text style={styles.text}>DESCRIPTION</Text></View>
        <View style={{flex:2}}><Text style={styles.text}>TOTAL</Text></View>
      </View>
    )
  }

  const ListItems = (data) => {
    return(
        <View style={{paddingVertical:5}}>
            {data.map((item,key) => {
              const Amount  = parseInt(item.amount)
              return (
                <View key={key} style={{flexDirection:'row',marginBottom:5}}>
                  <View style={{flex:6}}><Text style={styles.text}>Payment for invoice #{item.name}</Text></View>
                  <View style={{flex:2}}><Text style={styles.text}>RM{Amount.toFixed(2)}</Text></View>
                </View>
              ); 
            })}
        </View>
    )
}

const ListItem = (data) => {
  const Amount  = parseInt(data.amount)
  return(
        <View style={{flexDirection:'row',padding:5,borderBottomWidth:0.5,paddingBottom:10,minHeight:160}}>
            <View style={{flex:6}}><Text style={styles.text}>Payment for invoice #{data.orderid}</Text></View>
            <View style={{flex:2}}><Text style={styles.text}>RM{Amount.toFixed(2)}</Text></View>
       </View>
  )
}



function inWords (num) {

  const  a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  const  b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

        var number = parseFloat(num).toFixed(2).split(".");
        var num = parseInt(number[0]);
        var digit = parseInt(number[1]);

      if ((num = num.toString()).length > 12) return 'overflow';
      var n = ('00000000000' + num).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{1})(\d{2})$/);
      var d = ('00' + digit).substr(-2).match(/^(\d{2})$/);;
      if (!n) return; var str = '';
      str += (n[1] != 0) ? (Number(n[1]) > 99 ? a[Number(n[1][0])] + 'hundred ' : '') + (a[Number(n[1])] || b[n[1][1]] + ' ' + a[n[1][2]]) + 'billion ' : '';
      str += (n[2] != 0) ? (Number(n[2]) > 99 ? a[Number(n[2][0])] + 'hundred ' : '') + (a[Number(n[2])] || b[n[2][1]] + ' ' + a[n[2][2]]) + 'million ' : '';
      str += (n[3] != 0) ? (Number(n[3]) > 99 ? a[Number(n[3][0])] + 'hundred ' : '') + (a[Number(n[3])] || b[n[3][1]] + ' ' + a[n[3][2]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (Number(n[5]) !== 0) ? ((str !== '') ? '' : '') +
                  (a[Number(n[5])] || b[n[5][0]] + ' ' +
                      a[n[5][1]]) + '' : '';
      str += (d[1] != 0) ? ((str != '' ) ? "and " : '') + (a[Number(d[1])] || b[d[1][0]] + ' ' + a[d[1][1]]) + 'Cents Only!' : 'Only!';
      return 'MALAYSIA RINGGIT '+str.toUpperCase();
}




const BillSum = () => {
  return(
    <View style={{flexDirection:'row',marginLeft:20}}>
      <View style={{marginRight:15}}>
        <Text style={styles.text}>SUBTOTAL</Text>
        <Text style={styles.text}>TAX</Text>
        <Text style={styles.text}>ROUNDING ADJ</Text>
        <Text style={[styles.btext,{marginTop:10,paddingVertical:5,}]}>NETT TOTAL</Text>
      </View>
      <View>
        <Text style={styles.text}>: {details.amount}</Text>
        <Text style={styles.text}>: 0.00</Text>
        <Text style={styles.text}>: 0.00</Text>
        <View style={styles.pricebx}>
            <Text style={[styles.btext]}> RM {details.amount}</Text>
        </View>
      </View>
    </View>
  )
}

const ReformatTime = (data) => {
  if(!data) return null
  return moment(data.toDate()).format('YYYY-MM-DD hh:mm:ss');
}


const Terms = () => {
  return (
    <View style={{marginTop:5}}>
      <View style={{paddingBottom:5}}>
        <Text style={styles.stext}>* This receipt only valid upon clearance of the cheque</Text>
        <Text style={styles.stext}>* This is a system generated receipt which needs no signature</Text>
        <Text style={styles.stext}>* Please check all entries to ensure they are correct</Text>
        <Text style={styles.stext}>* Goods sold are not returnable</Text>
        <Text style={styles.stext}>* For any enquiries, call our office 9am-5pm ( Mon - Fri )</Text>
      </View>
      <Text style={styles.stext}>This is computer generated receipt, no signature required.</Text>
      <View style={{flexDirection:'row',alignContent:'flex-end',alignItems:'flex-end',justifyContent:'flex-end'}}>
        <Text style={[styles.stext,{marginLeft:20}]}>{ReformatTime(details.createdAt)}</Text>
      </View>
    </View>
  )
}


  

  return (
    <Document >
      <Page size="A5" style={styles.body}>

        

          <View style={styles.header} fixed>
            <LOGO/>
            <CompanyAddress/>
            <QRBOX />
          </View>
        <BillDetails/>
        <TableTh/>
        {ListItem(details)}

        
        <View style={{flexDirection:'row',marginTop:5}}>
            <View style={{flex:3}}><Text style={styles.stext}>{inWords(details.amount)}</Text></View>
            <View style={{flex:2,alignContent:'flex-end',alignItems:'flex-end'}}><Text style={[styles.btext]}>SUBTOTAL   :  RM {parseInt(details.amount).toFixed(2)}</Text></View>
        </View>
        <Terms/>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width:'50%'
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  address:{
    flex:1,
    alignContent:'center',
    alignItems:'center',
    textAlign:'center'
  },
  text: {
    fontSize: 9,
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:3
  },
  stext:{
    fontSize: 8,
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:2
  },
  btext:{
    fontSize: 10,
    fontWeight:'200',
    fontFamily: "Helvetica",
    color:'#111',
    marginBottom:2
  },
  headerbox: {
    width:60,
    height:60,
    alignContent:'center',
    alignItems:'center',
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center'
  },
  company:{
    flexDirection:'row',
    alignItems:'baseline'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  center:{
    flexDirection:'row',
    alignItems:'center',
    alignContent:'center'
  },
  boldtxt:{
    fontWeight:'bold',
    fontSize:10,
    color:'#111',
  }
});
