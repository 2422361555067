import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator } from 'react-native';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import DatePicker from "../../../../assets/component/datepicker";
import COLORS from "../../../../assets/component/colors";
import {ListDown,TextInput,Button } from "../../../../assets/component";
import {AddDebit,GetOrders,updateImageUrl } from "../../../../assets/js/firebase";
import {UploadData} from "../../../../assets/js/upload";
import FileUpload from './filesupload';
import PendingList from './debit/listpending';


export default function DebitsModal(props) {

    const {success} = props

    const [isLoading, setIsloading] = useState(false);
    const [values,setValues] = useState({'order':[],'amount':0,'balance':0,'payment':'bankin','dated':''})
    const [name, onName] = useState("");
    const [file, setFile] = useState("");

    const [error, setError] = useState("");

    const [showPending, setShowPending] = useState(true);



    useEffect(() => { 
        if(values.payment == 'cash'){
            setFile('')
        }
    }, [values]);

    function changeHandler (val,type){
        setValues( prevValues => {
          return { ...prevValues,[type]: val}
        })
      }




async function Validate(){
    setError('')
    var valid = true;

    if(!values.dated || values.dated.error){
        valid=false
    }

    if(values.amount <= 0 || isNaN(values.amount) || values.amount.error){
        changeHandler({error:"Amount must not be 0"},'amount')
        valid=false
    }


    if(!values.order){
        changeHandler({error:"Choose order"},'order')
        valid=false
    }

    if(values.order == 'others' && !name){
        changeHandler({error:"No name"},'order')
        valid=false
    }

    if(valid){
        setIsloading(true)
        const NAME = values.order === 'others'?name:values.order.company?values.order.company:values.order.name
        const gEtDoc = await AddDebit({'orders':values.order.length?values.order:null,'name':NAME,'amount':values.amount,status:'active','payment_method':values.payment,'date':values.dated},values.balance);
        if(file){
            const DownloadUrl = await UploadData(gEtDoc.id,file,'debits') 
            if(DownloadUrl){
                setIsloading(false)
                const YearDir = moment(gEtDoc.date.toDate()).format('YYYY');
                updateImageUrl('debits',YearDir,gEtDoc.id,DownloadUrl)
                gEtDoc['image'] = DownloadUrl
                success(gEtDoc)
            }
        }else{
            setIsloading(false)
            success(gEtDoc)
        }
    }

}

function ChangeAmt(index,val,balance){
    if(balance >= val){
        const NewOrder = [...values.order]
        NewOrder[index]['paying'] = val
        const sum = NewOrder.reduce((acc, o) => acc + parseFloat(o.paying), 0)
        changeHandler(sum,'balance')
        changeHandler(sum,'amount')
        changeHandler(NewOrder,'order')
        
    }
}


    
    const OrderSelect = (val) => {
        if(!val) return null
        if(val == 'others'){
            return (
                <View>
                    <TextInput title={'Name'} onChangeText={onName} value={name} remark={"Invest Deposit"}/>
                </View>
            )
        }
        return (
            <View>
                <TouchableOpacity onPress={()=>{changeHandler([],'order');changeHandler(0,'amount'),changeHandler(0,'balance')}}>
                    <Ionicons name='close' size={18} color={COLORS.Error} />
                </TouchableOpacity>
                {val.map((i,key)=>{
                    const Name = i.company?i.company:i.name
                    const Balance = parseFloat(i.amount)-parseFloat(i.paid)
                    return (
                    <View style={styles.orderSelect} key={key}>
                        <View style={{flex:1}}>
                            <Text>{Name}</Text>
                            <Text>Balance : RM{Balance.toFixed(2)}</Text>
                        </View>
                        <TextInput onChangeText={(val)=>ChangeAmt(key,val,Balance)} value={i.paying}/>
                    </View> 
                    )
                })}
                <TouchableOpacity style={styles.morebtn} onPress={()=>{setShowPending(true)}}>
                    <Ionicons name='add' size={18} color={'#aaa'} />
                    <Text>Add More</Text>
                </TouchableOpacity>
            </View>
        )
    }

    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }

    function OrderChoose(item){
        if(item == 'others'){
            changeHandler('others','order')
            changeHandler(0,'balance')
            changeHandler(0,'amount')
        }else{
            const found = values.order.some(el => el.id === item.id);
            if(!found){
                const Paid = item.paid?parseFloat(item.paid):0
                var Amt = parseFloat(item.amount)-Paid
                item['paying'] = Amt
                changeHandler([item,...values.order],'order')
                changeHandler(values.amount+Amt,'balance')
                changeHandler(values.amount+Amt,'amount')
            }
        }
        setShowPending(false)
    }



    if(showPending || !values.order) return <PendingList changeHandler={changeHandler} OrderChoose={OrderChoose}/>

    return(
        <View>
               {OrderSelect(values.order)}
               <DatePicker set={(val)=>changeHandler(val,'dated')} value={values.dated}/>
               <View  style={{marginVertical:5}}>
                <FileUpload value={file} set={setFile} />
                </View>
                <Error error={error}/>
                <ListDown list={['bankin','cash']} onPress={(val)=>changeHandler(val,'payment')} active={values.payment}/>
                <TextInput  required={true} title={'Amount'} onChangeText={(val)=>changeHandler(val,'amount')} value={values.amount} keyboardType="decimal-pad" maxLength={13} remark={"(RM)"}/>
                {!isLoading?<Button title={'ADD'} icon={'md-add-circle'} onPress={() => Validate()} color={'#426ff5'}/>:<ActivityIndicator size={'small'}/>}
        </View>
    )




}

const styles = StyleSheet.create({
    container:{
        padding:5,
        flexDirection:'row',
        backgroundColor:'#eee'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },

    orderSelect:{
        flexDirection:'row',
        borderWidth:1,
        borderRadius:4,
        paddingHorizontal:5,
        borderColor:'#ddd',
        flex:1,
        marginBottom:4,
    },
    item:{
        fontSize:14
    },
    morebtn:{
        marginVertical:4,
        borderRadius:5,
        borderWidth:1,
        borderColor:'#aaa',
        alignContent:'center',
        alignItems:'center',
        padding:4,
        flexDirection:'row'
    }
});
