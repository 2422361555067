import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Button from "../../../../assets/component/button";
import moment from 'moment';
import { PDFDownloadLink,PDFViewer} from "@react-pdf/renderer";
import PDFile from './pdf_salary';
import {GETACC} from '../../../../assets/js/getacc';
import { isLoading } from 'expo-font';

export default function Table(props) {

  const {tab,setList,list,loading,staff} = props


  const [sortasc, setSortAsc] = useState(true);
  const [user, setUser] = useState([]);


  useEffect(() => {
    GetACCOUNT()
  }, [tab]);

  async function GetACCOUNT(){
    const user = await GETACC();
    setUser(user)
  }


  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  






const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('Month',1)}
          {Th('Basic',1)}
          {Th('NETT (PAY)',1)}
          {Th('Updated',1)}
          <View style={{width:20}}/>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }



  const RenderItem = ({item,index}) => {
    const Basic = parseFloat(item.basic).toFixed(2)
    const Total = parseFloat(item.total).toFixed(2)

    return(
      <View style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1}}><Text style={styles.item}>{item.id}</Text></View>
                <View style={{flex:1}}><Text style={styles.item}>RM{Basic}</Text></View>
                <View style={{flex:1}}><Text style={styles.amt}>RM{Total}</Text></View>
                <View style={{flex:1}}><Text style={styles.item}>{ReformatTime(item.updated)}</Text></View>
                {Print(item)}
            </View>
      </View>
    )
}





  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY HH:MM');
    return format
  }




  const Print = (item) => {
    if(loading) return null
    return (
      <PDFDownloadLink document={<PDFile data={item} user={user} staff={staff}/>}  fileName={'salary_'+item.month} style={{ textDecoration: 'none' }}>
        {({loading})=>
          <Ionicons name='download-outline' size={18} color={'#aaa'} />
        }
      </PDFDownloadLink>
    )
  }



    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  category:{
    marginRight:5,
    color:'#04b8a9',
    fontWeight:'500',
    fontSize:14
  },
  created:{
    marginRight:5,
    color:'#aaa',
    fontWeight:'300',
    fontSize:10
  },
  amt:{
    marginRight:5,
    color:'#04b8a9',
    fontWeight:'500'
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    fontWeight:'bold',
    color:'#e07c00',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:10,
    paddingHorizontal:10,
    marginHorizontal:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  optionbtn:{
    padding:5
  },
  tickbox:{
    borderWidth:1,
    borderColor:'#aaa',
    borderRadius:2,
    alignContent:'center',
    alignItems:'center',
    width:20,
    height:20,
    marginHorizontal:5
  },

});
