
import { getFirestore,writeBatch,runTransaction ,query,getDoc, getDocs,startAfter,documentId,startAt,deleteDoc,updateDoc,collection,addDoc,doc,Timestamp,serverTimestamp,increment,orderBy,limit,where,arrayUnion,arrayRemove, setDoc, deleteField } from 'firebase/firestore';
import {getStorage,getDownloadURL ,ref,uploadBytesResumable} from "firebase/storage";
import {GETACC} from '../../assets/js/getacc';
import moment from 'moment';

const CreateNewId = async (type,id) => {

  const db = getFirestore();
  const user = await GETACC();

  const RefType = doc(db, "account/"+user.id);
  await setDoc(RefType, {[type+'_idseq']:id});
  

  return id

};

async function IncreaseID(user,type,start,multi){
  const db = getFirestore();

  const sfDocRef = doc(db, "account", user);

try {
  const NewIDD = await runTransaction(db, async (transaction) => {
    const sfDoc = await transaction.get(sfDocRef);
    if (!sfDoc.exists()) {
      CreateNewId(type,start)
      return NewIDD
    }

    const increased = type+'_idseq';
    const newPop = sfDoc.data()[increased] + (multi?multi:1);
    if (newPop) {
      transaction.update(sfDocRef, { [increased]: newPop });
      return newPop;
    } else {
      const Started = multi?multi+start:start
      transaction.update(sfDocRef, { [increased]: Started });
      return Started;
    }
  });
  return NewIDD

} catch (e) {
  // This will be a "population is too big" error.
  console.error(e);
}
}

export const AddCredit = async (data,category,subcategory) => {

  const db = getFirestore();
  const user = await GETACC()

  const CreditID = await IncreaseID(user.id,'credits',8000);

  var NewcatId = null

  if(CreditID){
      const batch = writeBatch(db);

      if(!data.image){
        delete data['image']
      }

      const DateFormat = moment(data.date,'DD-MM-YYYY')

      data['createdAt'] = serverTimestamp()
      data['date'] = Timestamp.fromDate(new Date(DateFormat));

      if(category){
        NewcatId = await CreateNewCategory(data['category'],category,subcategory);
        data['category'] = {'name':data['category'],'id':NewcatId,'category':category,'sub_category':subcategory}
      }

      const Ref = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/credits/"+CreditID);

      batch.set(Ref, data);

      const RefType = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/");
      
      const UpdateIncrese = {
        ['total_credits']:increment(data.amount),
        'last_updated_credits' :serverTimestamp()
      }

      batch.set(RefType,UpdateIncrese,{merge:true});

      await batch.commit();

      data['id'] = CreditID
      data['catid'] = NewcatId
      data['type'] = 'credit'


      return data
  }

};

export const UpdateCredit = async (data,increase) => {

  const db = getFirestore();
  const user = await GETACC()


  if(data.id){
      const batch = writeBatch(db);
      if(!data.image){
        delete data['image']
      }

      const DateFormat = moment(data.date,'DD-MM-YYYY')

      data['date'] = Timestamp.fromDate(new Date(DateFormat));

      const Ref = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/credits/"+data.id);

      batch.set(Ref, data);

      const RefType = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/");

      const UpdateIncrese = {
        ['total_credits']:increment(increase),
        'last_updated_credits' :serverTimestamp()
      }

      batch.set(RefType, UpdateIncrese,{merge:true});

      await batch.commit();

      return true
  }

};

export const CreateNewCategory = async (name,cat,sub) => {

  const db = getFirestore();
  const user = await GETACC();

  let Ref = doc(collection(db, "account/"+user.id+"/credits_category/"))
  await setDoc(Ref, { 'name':name,'status':true,'category':cat,'sub_category':sub,createdAt: serverTimestamp()});

  return Ref.id
};


  export const AddDebit = async (data,paid) => {

    const db = getFirestore();
    const user = await GETACC();
  
    const CreditID = await IncreaseID(user.id,'debits',19000);

  
    if(CreditID){
        const batch = writeBatch(db);

        data['createdAt'] = serverTimestamp()

        const YearDir = moment(data.date,'DD-MM-YYYY').format('YYYY')

        const DateFormat = moment(data.date,'DD-MM-YYYY')
        data['date'] = Timestamp.fromDate(new Date(DateFormat));

        if(data.orders){
          data['name'] = data.orders.length > 1 ? 'Bulk Order' : data.orders[0].name
          const Orderids = [...data.orders]
          data['orderid'] =Orderids.map(a => a.id)
        }
        

        const Ref = doc(db, "account/"+user.id+"/acc/"+YearDir+"/debits/"+CreditID);
        batch.set(Ref, data)

        const RefType = doc(db, "account/"+user.id+"/acc/"+YearDir+"/");

        const UpdateIncrese = {
          ['total_debits']:increment(data.amount),
          'last_updated_debits' :serverTimestamp()
        }
        

        batch.set(RefType,UpdateIncrese,{merge:true})

        if(data.orders){

          data.orders.forEach(el => {
            const Balance = el.amount - el.paid
            const RefUpdate = doc(db, "account/"+user.id+"/orders/"+el.id);
            const Paid = el.paying >= Balance ? 'paid' : 'pending'
            batch.update(RefUpdate, {payments: arrayUnion({'year':YearDir,'id':CreditID,'amount':el.paying}),'paid':increment(el.paying),status:Paid})
          });

        }
        data['id'] = CreditID
        data['type'] = 'debit'

        await batch.commit();
  
        return data
    }
  
  };

  export const updateStatus = async (root,id,status) => {

        const db = getFirestore();
        const user = await GETACC();
  
        const Ref = doc(db, "account/"+user.id+"/"+root+"s/"+id);
  
        const AddStatus = await updateDoc(Ref, {'status':status});
  
        return true
  
  };


  export const UpdateData = async (root,id,data) => {

    const db = getFirestore();
    const user = await GETACC();

    const Ref = doc(db, "account/"+user.id+"/"+root+"/"+id);
    delete data.id
    const AddStatus = await updateDoc(Ref, data);

    return data

  };


  export const AddAccount = async (data) => {

    const db = getFirestore();

    const docRef = await addDoc(collection(db, "account"), data);
    data['id'] = docRef.id

    return data

};


export const UpdateAccount = async (id,data) => {

  const db = getFirestore();

  const Ref = doc(db, "account/"+id);

  await updateDoc(Ref, data);

  data['id'] = id

  return data

};


export const UpdateDoc = async (id,route,data) => {


  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  const Ref = doc(db, "account/"+user.id+"/"+route+"/"+id);

  batch.update(Ref, data)

  await batch.commit();
  data['createdAt'] = Timestamp.now();
  data['id'] = id
  return data

};


export const AddDoc = async (route,data) => {


  const db = getFirestore();
  const user = await GETACC();
  data['createdAt'] = serverTimestamp();
  const docRef = await addDoc(collection(db, "account/"+user.id+"/"+route), data);
  data['createdAt'] = Timestamp.now();
  data['id'] = docRef.id

  return data
};


  export const updateImageUrl = async (type,year,id,url) => {

    const db = getFirestore();
    const user = await GETACC();

    console.log("account/"+user.id+"/acc/"+year+"/"+type+"/"+id)
    
    const Ref = doc(db, "account/"+user.id+"/acc/"+year+"/"+type+"/"+id);

    await updateDoc(Ref, {'image':url});

    return true

};

export const updateImageLink = async (route,url) => {

  const db = getFirestore();
  const user = await GETACC();

  
  const Ref = doc(db, "account/"+user.id+"/"+route);

  await updateDoc(Ref, {'image':url});

  return true

};



  export const deleteCredit = async (data) => {

    const db = getFirestore();
    const user = await GETACC();


const batch = writeBatch(db);

const DateFormat = moment(data.date.toDate())

const Ref = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/credits/"+data.id);

batch.update(Ref, {'status':'delete'})

const RefType = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/");

const UpdateIncrese = {
  ['total_credits']:increment(-data.amount),
  'last_updated_credits' :serverTimestamp()
}

batch.update(RefType,UpdateIncrese)


    await batch.commit();

    return true

};

export const deleteDebit = async (data) => {

  const db = getFirestore();
  const user = await GETACC();
  
  const DateFormat = moment(data.date.toDate()).format('YYYY')

const batch = writeBatch(db);

const Ref = doc(db, "account/"+user.id+"/acc/"+DateFormat+"/debits/"+data.id);

batch.update(Ref, {'status':'delete'})

const RefType = doc(db, "account/"+user.id+"/acc/"+DateFormat+"/");

const UpdateIncrese = {
  ['total_debits']:increment(-data.amount),
  'last_updated_debits' :serverTimestamp()
}

batch.update(RefType, UpdateIncrese)

if(data.orderid){
  if(typeof data.orderid !== 'object'){
    const Ref2 = doc(db, "account/"+user.id+"/orders/"+data.orderid);
    batch.update(Ref2, {'status':'pending','paid':increment(-data.amount),'payments': arrayRemove({'year':DateFormat,'id':parseInt(data.id),'amount':data.amount})})
  }else{
    const asyncRes2 = await Promise.all(data.orderid.map(async(item) => await GetDocument(item,'orders')));
    data.orderid.map((el)=>{
      const Foundata = asyncRes2.find(e => e.id == el);
      const edta = Foundata.payments.find(e => e.id == data.id);
      console.log(edta)
      const Ref2 = doc(db, "account/"+user.id+"/orders/"+el);
      batch.update(Ref2, {'status':'pending','paid':increment(-parseFloat(edta.amount)),'payments': arrayRemove(edta)})
    })
  }
}



  await batch.commit();

  return true

};

export const GetDebit = async (year,id) => {

  const db = getFirestore();
  const user = await GETACC();
  
  const refDebit = "account/"+user.id+"/acc/"+year+"/debits"

  const docRef = doc(db, refDebit, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var docData = docSnap.data();
    docData['id'] = docSnap.id
    return docData
  } 

  return null

};


export const GetPaids = async () => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/orders"

  const q = query(collection(db, ref), where("status", "==", 'active'),orderBy("createdAt","desc"));

  const documentSnapshots = await getDocs(q);

  if(documentSnapshots.size){
    var dataArray = []

    documentSnapshots.forEach(doc => {
      const data = doc.data()
      data['id'] = doc.id
      dataArray.push(data)
    });

    return dataArray
  }

  return null

};

export const GetReports = async (year) => {

  const db = getFirestore();
  const user = await GETACC();

  const refDebit = "account/"+user.id+"/acc"

  const docRef = doc(db, refDebit, year);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var docData = docSnap.data();
    return docData
  } 
  return null

};


export const GetReportList = async (year,type) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref1 = "account/"+user.id+"/acc/"+year+'/'+type

  const q = query(collection(db, ref1), where("status", "==", 'active'));

  const documentSnapshots = await getDocs(q);

  console.log("Get All "+year+" "+type+" Data From Firestore")

  var backup = []

  if(documentSnapshots.size){

    documentSnapshots.forEach(doc => {
      const data = doc.data()
      backup.push(data)

    });
  }


  const RefGen = doc(db, "account/"+user.id+"/acc/", year);



  await updateDoc(RefGen, {["last_generated_"+type] :serverTimestamp()});


  downloadTextFile(backup,type,user,year)

  return backup

};


function downloadTextFile(data,type,user,year) {
  const fileData = JSON.stringify(data);

  const FileName = year+'_'+type+".json"

  const blob = new Blob([fileData], { type: "text/plain" });

  const storage = getStorage();
  const storageRef = ref(storage,`/files/${user.id}/backups/${FileName}`)
  const uploadTask = uploadBytesResumable(storageRef, blob);

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = user.name+'_'+FileName;
  link.href = url;
  link.click();
}

export const GetBackupFromServer = async (year,types) => {
  const user = await GETACC();

  const FileName = year+'_'+types+'.json'
  const storage = getStorage();
  const storageRef = ref(storage,`/files/${user.id}/backups/${FileName}`)

  const Download = getDownloadURL(ref(storage, storageRef))
  .then((url) => {
    return url
  }).catch((e)=> {
    console.log(e)
    return null
  })

  return Download

}

export const GetCreditList = async (Year,type,sub) => {

  const db = getFirestore();
  const user = await GETACC();


  const ref = "account/"+user.id+"/acc/"+Year+'/credits'
  
  var q = ''

  if(sub){
    console.log(sub)
    q = query(collection(db, ref), where("status", "==", 'active'),where("category.category", "==", type),where("category.sub_category", "==", sub),orderBy("createdAt","desc"));
  }else{
    q = query(collection(db, ref), where("status", "==", 'active'),where("category.category", "==", type),orderBy("createdAt","desc"));
  }

  const documentSnapshots = await getDocs(q);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

  var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

      var result = [];
      dataArray.reduce(function(res, value) {
        const rndclr = "#" + ((1<<24)*Math.random() | 0).toString(16)
        if (!res[value.category.name]) {
          res[value.category.name] = { name: value.category.name, amount: 0 ,legendFontSize: 15,legendFontColor:'blue',color:rndclr};
          result.push(res[value.category.name])
        }
        res[value.category.name].amount += parseFloat(value.amount);
        return res;
      }, {});


  return {'list':dataArray,'group':result}

};


export const GetExspensesList = async (year) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/acc/"+year+'/credits'

  const q = query(collection(db, ref), where("status", "==", 'active'));

  const documentSnapshots = await getDocs(q);

  if(documentSnapshots.size){


    var dataArray = []
    documentSnapshots.forEach(doc => {
      const data = doc.data()
      dataArray.push(data)
    });

    var result = [];
    dataArray.reduce(function(res, value) {
      const rndclr = "#" + ((1<<24)*Math.random() | 0).toString(16)
      if (!res[value.category.category]) {
        res[value.category.category] = { name: value.category.category, amount: 0 ,legendFontSize: 15,legendFontColor:'blue',color:rndclr};
        result.push(res[value.category.category])
      }
      res[value.category.category].amount += parseFloat(value.amount);
      return res;
    }, {});

    return result
  }

  return null

};


export const UpdateExspensesCat = async (data,cat,year) => {

  const db = getFirestore();
  const user = await GETACC();
  const batch = writeBatch(db);

  data.forEach(el => {
    const Id = el.id
    delete el.id

    if(el.category.id){
      const refB =  doc(db, "account/"+user.id+"/credits_category/"+el.category.id)
      batch.update(refB, {category:cat})
    }

    const refC =  doc(db, "account/"+user.id+"/acc/"+year+"/credits/"+Id)
    batch.update(refC, el)
    
  });

  await batch.commit();

  return true

};

export const setReport = async (year,data) => {

  const db = getFirestore();
  const user = await GETACC();

  const Ref = doc(db, "account/"+user.id+"/acc/"+year);

  const AddStatus = await updateDoc(Ref, {'report':data});

  return true

};

// temp can delete
export const GetReportList2 = async (year) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/acc/"+year+'/credits'

  const datefrm = Timestamp.fromDate(new Date("2022-10-01"+',12:00:00 AM'));
  const dateto  = Timestamp.fromDate(new Date("2022-10-09"+',12:00:00 AM'));



  const q = query(collection(db, ref), where("status", "==", 'active'),where('createdAt', '>=', datefrm) , where('createdAt', "<", dateto));

  const documentSnapshots = await getDocs(q);

  if(documentSnapshots.size){
    var dataArray = []

    documentSnapshots.forEach(doc => {
      const data = doc.data()
      data['id'] = doc.id
      dataArray.push(data)
    });

    return dataArray
  }

  return null

};


export const GetDocument = async (id,route) => {
  

  const db = getFirestore();
  const user = await GETACC();

  const refDebit = "account/"+user.id+"/"+route

  const docRef = doc(db, refDebit, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var docData = docSnap.data();
    docData['id'] = docSnap.id
    return docData
  } 

  return null

};



export const GetBydateCreditDebits = async (active,from,to) => {
  

  const db = getFirestore();
  const user = await GETACC();

  const Year   = moment(from,'DD-MM-YYYY').format('YYYY');

  const ref = "account/"+user.id+"/acc/"+Year+'/'+active+'s'
  


      const From  = moment(from,'DD-MM-YYYY').startOf("day")
      const endOfMonth   = moment(to,'DD-MM-YYYY').endOf("day")
      let NewFrom = new Date(From);
      let To = new Date(endOfMonth);
      const q = query(collection(db, ref),where("date", ">=", NewFrom),where("date", "<=", To), where("status", "==", 'active'),orderBy("date","desc"));
    

      const documentSnapshots = await getDocs(q);

      var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

  return dataArray

};


export const GetByIdNameCreditDebits = async (type,from,to) => {
  

  const db = getFirestore();
  const user = await GETACC();

  const Year   = moment().format('YYYY');

  const ref = "account/"+user.id+"/acc/"+Year+'/'+type['active']+'s'

  const From  = moment(from,'DD-MM-YYYY').startOf("day")
  const endOfMonth   = moment(to,'DD-MM-YYYY').endOf("day")
  let NewFrom = new Date(From);
  let To = new Date(endOfMonth);

  var dataArray = []


  if(type.type == 'Name'){

    const Q3 = query(collection(db, "account/"+user.id+"/orders"),where("mobile", "==", '+60137010888'));
    var dataArray3 = []
    const documentSnapshots3 = await getDocs(Q3);
      documentSnapshots3.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray3.push(data)
      });
      console.log(dataArray3)

    const Q = query(collection(db, ref),where("date", ">=", NewFrom),where("date", "<=", To), where("status", "==", 'active'),where('name', "==", type['name']));
    const documentSnapshots = await getDocs(Q);
      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });
  }
  
  if(type.type == 'ID'){
    const refDebit = "account/"+user.id+"/acc/"+Year+'/'+type['active']+'s'
    const docRef = doc(db, refDebit, type.name);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      var docData = docSnap.data();
      docData['id'] = docSnap.id
      return [docData]
    } 
  }
  
  return dataArray

};



export const GetCustomerDataByMobile = async (mobile) => {
  

  const db = getFirestore();
  const user = await GETACC();


  const Q3 = query(collection(db, "account/"+user.id+"/orders"),where("mobile", "==", mobile),orderBy("createdAt","desc"),limit(1));
    var dataArray3 = []
    const documentSnapshots3 = await getDocs(Q3);
      documentSnapshots3.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray3.push(data)
      });
  

  return dataArray3

};



export const GetCreditDebits = async (active,LimitPerload,Year) => {

  const db = getFirestore();
  const user = await GETACC();


  const ref = "account/"+user.id+"/acc/"+Year+'/'+active+'s'
  


  const q = query(collection(db, ref), where("status", "==", 'active'),orderBy("date","desc"),limit(LimitPerload));

  const documentSnapshots = await getDocs(q);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

  var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

  return {'last':lastVisible,'list':dataArray}

};


export const GetCreditDebitsNext = async (active,last,LimitPerload,Year) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/acc/"+Year+'/'+active+'s'

  const q = query(collection(db, ref), where("status", "==", 'active'),orderBy("date","desc"), startAfter(last),limit(LimitPerload));

  const documentSnapshots = await getDocs(q);


  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];


  if(documentSnapshots.size){
    var dataArray = []

    documentSnapshots.forEach(doc => {
      const data = doc.data()
      data['id'] = doc.id
      dataArray.push(data)
    });

    return {'last':lastVisible,'list':dataArray}
  }

  return null

};


export const GetOrders = async (root,active,LimitPerload,last) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/"+root

  var q = ''
  if(LimitPerload){
      if(last){
        q = query(collection(db, ref), where("status", "==", active),orderBy("createdAt","desc"), startAfter(last),limit(LimitPerload));
      }else{
        q = query(collection(db, ref), where("status", "==", active),orderBy("createdAt","desc"),limit(LimitPerload))
      }
  }else{
    q = query(collection(db, ref), where("status", "==", active),orderBy("createdAt","desc"))
  }


  const documentSnapshots = await getDocs(q);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

  if(documentSnapshots.size){

      var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

    return {'last':lastVisible,'list':dataArray}

  }

  return last?null:{'last':[],'list':[]}

};


export const GetDelivers = async (root,active,LimitPerload,last) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/"+root

  var q = ''

  if(last){
    q = query(collection(db, ref), where("status", "==", active),orderBy("last_updated","desc"), startAfter(last),limit(LimitPerload));
  }else{
    q = query(collection(db, ref), where("status", "==", active),orderBy("last_updated","desc"),limit(LimitPerload))
  }



  const documentSnapshots = await getDocs(q);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

  if(documentSnapshots.size){

      var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

    return {'last':lastVisible,'list':dataArray}

  }

  return last?null:{'last':[],'list':[]}

};


export const GetOrdersOnline = async (root,company,active,LimitPerload,last) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/"+root

  var q = ''
  if(LimitPerload){
      if(last){
        q = query(collection(db, ref), where("company", "==", company), where("status", "==", active),orderBy("createdAt","desc"), startAfter(last),limit(LimitPerload));
      }else{
        q = query(collection(db, ref), where("company", "==", company), where("status", "==", active),orderBy("createdAt","desc"),limit(LimitPerload))
      }
  }else{
    q = query(collection(db, ref), where("company", "==", company), where("status", "==", active),orderBy("createdAt","desc"))
  }


  const documentSnapshots = await getDocs(q);

  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

  if(documentSnapshots.size){

      var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

    return {'last':lastVisible,'list':dataArray}

  }

  return last?null:{'last':[],'list':[]}

};


export const AddOrder = async (data) => {

  const db = getFirestore();
  const user = await GETACC();

  const CreditID = await IncreaseID(user.id,'order',60000);

  data['status'] = 'pending';
  data['createdAt'] = serverTimestamp();


    if(CreditID){
      const batch = writeBatch(db);
      const Ref = doc(db, "account/"+user.id+"/orders/"+CreditID);
      batch.set(Ref,data);


      data.products.forEach(element => {
            if(element.type == 'stock'){
              const refB = doc(db, "account/"+user.id+"/stock/"+element.id);
              batch.update(refB, {stock:increment(-element.qty)})
            }
          });

      await batch.commit();
      data['id'] = CreditID.toString();
      return data
  }
};


export const ConfirmOrder = async (data) => {
  const quoId =  data.id
  delete data.id
  data['quotations'] = quoId
  const Added = await AddOrder(data)

  if(Added.id){
    data['invoice'] = Added.id
    data['status'] = 'confirmed'
    const Updated = UpdateData('quotations',quoId,data)
    return Updated
  }

};




export const AddQuotation = async (data) => {

  const db = getFirestore();
  const user = await GETACC();

  const CreditID = await IncreaseID(user.id,'quotation',50000);

  data['status'] = 'pending';
  data['createdAt'] = serverTimestamp();


    if(CreditID){
      const batch = writeBatch(db);
      const Ref = doc(db, "account/"+user.id+"/quotations/"+CreditID);
      batch.set(Ref,data);


      await batch.commit();
      data['id'] = CreditID.toString();
      data['createdAt'] = Timestamp.now();
      return data
  }
};

export const UpdateQuotation = async (data,id) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  data['updated'] = serverTimestamp();

  const Ref = doc(db, "account/"+user.id+"/quotations/"+id);

  batch.update(Ref, data)

  await batch.commit();

  data['updated'] = Timestamp.now();

  data['id'] = id

  return data

};




  export const AddShopeeOrder = async (data) => {

    const db = getFirestore();
    const user = await GETACC();
  
    const CreditID = await IncreaseID(user.id,'order',90000);

    data['status'] = 'pending';
    data['createdAt'] = serverTimestamp();
  

      if(CreditID){
        const batch = writeBatch(db);
        const Ref = doc(db, "account/"+user.id+"/online_order/"+CreditID);
        batch.set(Ref,data);


        data.products.forEach(element => {
              if(element.type == 'stock'){
                const refB = doc(db, "account/"+user.id+"/stock/"+element.id);
                batch.update(refB, {stock:increment(-element.qty)})
              }
            });

        await batch.commit();
        data['id'] = CreditID.toString();
        data['createdAt'] = Timestamp.now();
        return data
    }
  };





  export const UpdateDriver = async (order,status,data,type,AddDriver) => {
    const db = getFirestore();
    const user = await GETACC();
    const batch = writeBatch(db);

    const ID = order.id

    const Newdata = {...data}
    Newdata['updated'] = serverTimestamp();
    Newdata['delivery_status'] = arrayUnion({'status':status,'updated':Timestamp.now()})

    if(!Newdata.driver.id){
      delete Newdata.driver.id
      let Ref3 = doc(collection(db, "account/"+user.id+"/transporter_list"))
      batch.set(Ref3, {...Newdata.driver, ['createdAt']:serverTimestamp(),['status']:'active'})
      Newdata.driver['id'] = Ref3.id
    }

    const Ref = doc(db, "account/"+user.id+"/"+type+"/"+ID);
    batch.update(Ref, Newdata)
    


    // Delivery
    const picked = (({ delivery,delivery_status }) => ({delivery,delivery_status }))(Newdata);
    picked['type'] = type
    picked['name'] = data.driver.name
    picked['mobile'] = data.driver.mobile
    picked['driver_id'] = Newdata.driver.id
    picked['status'] = 'pending'
    picked['createdAt'] = serverTimestamp();
    picked['last_updated'] = serverTimestamp();
    picked['paid_id'] = order.paid_id?order.paid_id:null;
    picked['company_transporter'] = Newdata.driver['in-house'];


    picked['customer_mobile'] = order.mobile?order.mobile:data.mobile
    picked['remark'] = data.remark

    const Ref2 = doc(db, "account/"+user.id+"/delivery/"+ID);
    if(AddDriver){
      batch.set(Ref2, picked)
    }else{
      batch.update(Ref2, picked)
    }

    const AddStatus = [...data.delivery_status,{'status':status,'updated':null}]
    picked['delivery_status'] = AddStatus


    await batch.commit();


    return Newdata.driver

  };

  export const CURRENT_ACC = async () => {

    const user = await GETACC();

    return user

  };

  export const UpdateOrder = async (data,id,prevOrder) => {

    const db = getFirestore();
    const user = await GETACC();

    const batch = writeBatch(db);

    data['updated'] = serverTimestamp();

    const Ref = doc(db, "account/"+user.id+"/orders/"+id);

    prevOrder.forEach(element => {
      if(element.type == 'stock'){
        const refB = doc(db, "account/"+user.id+"/stock/"+element.id);
          batch.update(refB, {stock:increment(element.qty)})
      }
    });

    data.products.forEach(element => {
      if(element.type == 'stock'){
          const refC = doc(db, "account/"+user.id+"/stock/"+element.id);
          batch.update(refC, {stock:increment(-element.qty)})
      }
    });


    batch.update(Ref, data)

    await batch.commit();

    data['id'] = id

    return data

  };


  export const UpdateOrderShopee = async (data,id,prevOrder) => {

    const db = getFirestore();
    const user = await GETACC();

    const batch = writeBatch(db);

    data['updated'] = serverTimestamp();

    const Ref = doc(db, "account/"+user.id+"/online_order/"+id);

    prevOrder.forEach(element => {
      if(element.type == 'stock'){
        const refB = doc(db, "account/"+user.id+"/stock/"+element.id);
          batch.update(refB, {stock:increment(element.qty)})
      }
    });

    data.products.forEach(element => {
      if(element.type == 'stock'){
          const refC = doc(db, "account/"+user.id+"/stock/"+element.id);
          batch.update(refC, {stock:increment(-element.qty)})
      }
    });


    batch.update(Ref, data)

    await batch.commit();

    data['id'] = id

    return data

  };

  export const CancelOrder = async (id,dir) => {

    const db = getFirestore();
    const user = await GETACC();

    const Ref = doc(db, "account/"+user.id+"/"+dir+"/"+id);

    const batch = writeBatch(db);

      // increase stock back if order cancel
    const Orderdetail = await GetDocument(id,dir)

      Orderdetail['products'].forEach(element => {
        if(element.type == 'stock'){
          const refB = doc(db, "account/"+user.id+"/stock/"+element.id);
          batch.update(refB, {stock:increment(element.qty)})
        }
      });

    batch.update(Ref, {'status':'cancel'})

    const Ref3 = doc(db, "account/"+user.id+"/delivery/"+id);
    batch.delete(Ref3)

    await batch.commit();

    return true

};

export const DeleteDelivery = async (id,type) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  const Ref = doc(db, "account/"+user.id+"/"+type+"/"+id);
  batch.update(Ref, {'driver':deleteField()})


  const Ref3 = doc(db, "account/"+user.id+"/delivery/"+id);
  batch.delete(Ref3)

  await batch.commit();

  return true

};





export const GetAllDocs = async (root,active) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/"+root

  var que = null

  if(active){
      que = query(collection(db, ref), where("status", "==", active),orderBy("createdAt","desc"));
  }else{
      que = query(collection(db, ref), orderBy("createdAt","desc"));
  }


  const documentSnapshots = await getDocs(que);

  var dataArray = []

  if(documentSnapshots.size){
      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });
    }

  return dataArray

};


export const GetDeliveryDocs = async (active) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/delivery"

  var que = null
  
  if(active == 'paid'){
      que = query(collection(db, ref), where("paid_id", '!=', null), orderBy("paid_id","desc"),limit(30));
  }else{
      que = query(collection(db, ref), where("company_transporter", '==', false), where("paid_id", '==', null), orderBy("createdAt","desc"));
  }


  const documentSnapshots = await getDocs(que);

  var dataArray = []

  if(documentSnapshots.size){
      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });
    }

  return dataArray

};


export const UpdateDeliveryDocs = async (picked,amount) => {

  const db = getFirestore();
  const user = await GETACC();
  const batch = writeBatch(db);

  let pickedId = picked.map(a => a.id);

  var credit = {}
    credit['category'] = {'category': "Transport",'name':  'Transporter ('+picked[0].name+')','sub_category': 'Partner'}
    credit['partner_id'] = picked[0].driver_id;
    credit['date'] = new Date();
    credit['status'] = 'active'
    credit['amount'] = parseFloat(amount)
    credit['delivered'] = pickedId
    const Credit = await AddCredit(credit)

  if(Credit){
      pickedId.forEach(el => {
          const Ref = doc(db, "account/"+user.id+"/delivery/"+el);
          batch.update(Ref, {'paid_id':Credit.id})
      });
      await batch.commit();

      return {'credit':Credit.id,'ids':pickedId}
  }

  return null

};


export const GetProductsStock = async (id) => {

  const db = getFirestore();
  const user = await GETACC();


  const ref = "account/"+user.id+"/stock"

  const q = query(collection(db, ref), where("product", "==", id),where("status", "==", 'active'),orderBy("createdAt","desc"));

  const documentSnapshots = await getDocs(q);

  var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

  return dataArray

};


export const GetItemDetails = async (id,route) => {

  const db = getFirestore();
  const user = await GETACC();

  const refDebit = "account/"+user.id+"/"+route

  const docRef = doc(db, refDebit, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var docData = docSnap.data();
    docData['id'] = docSnap.id
    return docData
  } 

  return null

};

export const GetStockList = async () => {

  const db = getFirestore();
  const user = await GETACC();


  const ref = "account/"+user.id+"/stock"

  const q = query(collection(db, ref),where("stock", ">=", 1),where("status", "==", 'active'));

  const documentSnapshots = await getDocs(q);

  var dataArray = []

      documentSnapshots.forEach(doc => {
        const data = doc.data()
        data['id'] = doc.id
        dataArray.push(data)
      });

  return dataArray

};

export const getCreditTypes = async () => {

  const db = getFirestore();
  const user = await GETACC();


  const ref = "account/"+user.id+"/credits_category"

  const q = query(collection(db, ref), where("status", "==", true));

  const documentSnapshots = await getDocs(q);


    var DataArray = []
    var Category = []

    documentSnapshots.forEach(doc => {
      const data = doc.data()
      data['id'] = doc.id

      if (!Category.includes(data.category)) {
          Category.push(data.category);
      }

      DataArray.push(data)
    });
    return {'base':DataArray,'active':DataArray,'category':Category}


};



export const UpdateItemStatus = async (id,status,route) => {

  const db = getFirestore();
  const user = await GETACC();

  const Ref = doc(db, "account/"+user.id+"/"+route+"/"+id);

  await updateDoc(Ref, {'status':status});

  return true

};

export const GetDocsByids = async (route,ids) => {

  const db = getFirestore();
  const user = await GETACC();

  const ref = "account/"+user.id+"/"+route

  const q = query(collection(db, ref), where(documentId(), 'in', ids));

  const documentSnapshots = await getDocs(q);

  if(documentSnapshots.size){
    var dataArray = []

    documentSnapshots.forEach(doc => {
      const data = doc.data()
      data['id'] = doc.id
      dataArray.push(data)
    });

    return dataArray
  }

  return null
};

export const AddProduct = async (data) => {

  const db = getFirestore();
  const user = await GETACC();

  const CreditID = await IncreaseID(user.id,'product',100000);

  data['createdAt'] = serverTimestamp();
  data['status'] = 'active';
  if(data.type != 'service'){
    delete data['amount']
  }

  if(CreditID){
      const Ref = doc(db, "account/"+user.id+"/products/"+CreditID);
      await setDoc(Ref, data);

      data['id'] = CreditID
      data['createdAt'] = Timestamp.now()
      return data
  }

};

export const AddItem = async (data,route) => {

  const db = getFirestore();
  const user = await GETACC();

    data['createdAt'] = serverTimestamp();

    const batch = writeBatch(db);

    if(route.split("/").length % 2 == 0){
      const nycRef = doc(db,  "account/"+user.id+"/"+route);
      batch.set(nycRef, data)
      data['id'] = nycRef.id
    }else{
      const nycRef = doc(collection(db, "account/"+user.id+"/"+route));
      batch.set(nycRef,data);
      data['id'] = nycRef.id
    }


    await batch.commit();

    data['createdAt'] = Timestamp.now()
    
    return data

};


export const deleteStock = async (data) => {

  const db = getFirestore();
  const user = await GETACC();


  const batch = writeBatch(db);

  const Ref = doc(db, "account/"+user.id+"/stock/"+data.id);

  batch.update(Ref, {'status':'delete'})

  const refB = doc(db, "account/"+user.id+"/products/"+data.product);
  batch.update(refB, {stock:increment(-data.stock)})


  await batch.commit();

  return true

};


export const defectStock = async (data,stock) => {

  const db = getFirestore();
  const user = await GETACC();


  const batch = writeBatch(db);

  const Ref = doc(db, "account/"+user.id+"/stock/"+data.id);

  batch.update(Ref, {stock:increment(-stock)})


  const RefD = doc(collection(db, "account/"+user.id+"/defects"));

  batch.set(RefD,{'stock_id':data.id,'createdAt':serverTimestamp(),'product':data.product,'stock':stock});


  await batch.commit();

  return true

};


export const UpdateStock = async (id,data) => {

  const db = getFirestore();
  const user = await GETACC();


  const batch = writeBatch(db);

  const Ref = doc(db, "account/"+user.id+"/stock/"+id);

  batch.update(Ref, data)


  await batch.commit();

  return true

};

export const AddStaff = async (data) => {

  const db = getFirestore();
  const user = await GETACC();

  const CreditID = await IncreaseID(user.id,'staffid',8500000);

  data['createdAt'] = serverTimestamp();
  data['status'] = 'active';


  if(CreditID){
      const Ref = doc(db, "account/"+user.id+"/staff/"+CreditID);
      await setDoc(Ref, data);

      data['id'] = CreditID
      data['createdAt'] = Timestamp.now()
      return data
  }

};

export const ApprovedClaims = async (staffid,items,sum) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  const Ref = doc(collection(db, "account/"+user.id+"/claims"));

  var ClaimsList = []
  items.forEach(element => {
    const refC = doc(db, "account/"+user.id+"/staff/"+staffid+"/claims/"+element.id);
    ClaimsList.push(element.id)
    batch.update(refC, {status:'approved','updated':serverTimestamp(),'approved':Ref.id})
  });

  batch.set(Ref,{'claim_id':ClaimsList,'createdAt':serverTimestamp(),'amount':sum,'status':'pending','staff':staffid});

  await batch.commit();

  return true

};


export const AddSalary = async (data,route) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  data['createdAt'] = Timestamp.now()
  data['status'] = 'pending'

  const Ref = doc(collection(db, "account/"+user.id+"/salary/months/"+data.month));
  batch.set(Ref,data);

  data['salary_id'] = Ref.id

  const nycRef = doc(db,  "account/"+user.id+"/"+route);
  batch.set(nycRef, data)

  await batch.commit();

  data['createdAt'] = Timestamp.now()
  data['id'] = Ref.id


  return data


};


export const UpdateSalary = async (data,route,salaryId) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  data['createdAt'] = Timestamp.now()
  data['status'] = 'pending'

  const nycRef = doc(db,  "account/"+user.id+"/"+route);
  batch.update(nycRef, data)

  const Ref = doc(db, "account/"+user.id+"/salary/months/"+data.month+"/"+salaryId);

  batch.update(Ref,data);

  await batch.commit();

  data['createdAt'] = Timestamp.now()
  data['id'] = salaryId

  return data


};

export const PaidShopee = async (items,sum,company) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);


  var Orderlist = []
  var OrderId = []
  items.forEach(element => {
    Orderlist.push(element.id)
    OrderId.push(element.orderid)
  });

  var debit = {}
  debit['date'] = new Date();
  debit['name'] = company
  debit['status'] = 'active'
  debit['amount'] = sum
  debit['shopee_orders'] = Orderlist
  debit['shopee_orders_id'] = OrderId
  debit['payment_method'] = company
  
  const Add = await AddDebit(debit,sum)

  items.forEach(element => {
    const refC = doc(db, "account/"+user.id+"/online_order/"+element.id);
    batch.update(refC, {status:'paid','updated':serverTimestamp(),'paid_id':Add.id})
  });


  await batch.commit();

  return true

};


export const RejectClaims = async (staffid,items) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);


  items.forEach(element => {
    const refC = doc(db, "account/"+user.id+"/staff/"+staffid+"/claims/"+element.id);
    batch.update(refC, {status:'reject','updated':serverTimestamp()})
  });

  await batch.commit();

  return true

};


export const UpdateClaimStatus = async (item) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  const refC = doc(db, "account/"+user.id+"/claims/"+item.id);
  batch.update(refC, {status:item.status,'updated':serverTimestamp()})

  if(item.status == 'paid'){
    var credit = {}
    credit['category'] = {'category': "claim",'name':  'claim ('+item.staff+')','sub_category': item.staff}
    credit['date'] = new Date();
    credit['status'] = 'active'
    credit['amount'] = item.amount
    credit['claim'] = item.id
    credit['staff'] = item.staff
    await AddCredit(credit)
  }

  await batch.commit();

  return true

};




export const UpdateSalaryStatus = async (item) => {

  const db = getFirestore();
  const user = await GETACC();

  const batch = writeBatch(db);

  const LengthStaff = item.length

  var CreditID = await IncreaseID(user.id,'credits',8000,LengthStaff);

  var StartID = CreditID - LengthStaff -1

  item.forEach(async element => {

    StartID++

    const refS = doc(db, "account/"+user.id+"/staff/"+element.staff+'/salary/'+element.month);
    batch.update(refS, {status:'paid','updated':serverTimestamp()})
    const refC = doc(db, "account/"+user.id+"/salary/months/"+element.month+'/'+element.id);

    var credit = {}
    credit['category'] = {'category': "Salary",'name':  'Salary ('+element.name+')','sub_category': element.staff}
    credit['date'] = new Date();
    credit['status'] = 'active'
    credit['amount'] = element.total
    credit['staff'] = element.staff

    const DateFormat = moment(credit.date,'DD-MM-YYYY')

    credit['createdAt'] = serverTimestamp()
    credit['date'] = Timestamp.fromDate(new Date(DateFormat));
  
        const Ref = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/credits/"+StartID);
  
        batch.set(Ref, credit);
  
        const RefType = doc(db, "account/"+user.id+"/acc/"+DateFormat.format('YYYY')+"/");
        
        const UpdateIncrese = {['total_credits']:increment(credit.amount),'last_updated_credits' :serverTimestamp()}
  
        batch.set(RefType,UpdateIncrese,{merge:true});


        batch.update(refC, {status:'paid','updated':serverTimestamp(),'paid_id':StartID})

  });

  await batch.commit();

  return item

};

