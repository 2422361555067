import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert,Button,Linking} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {Copy} from "../../../assets/component";
import Edit from "../accounts/edit";
import {deleteCredit} from "../../../assets/js/firebase";

export default function Table(props) {

  const {tab,setList,list,next,loading,nav} = props

  
  const [modified, setModified] = useState(null);
  const [option, setOption] = useState(null);
  const [sortasc, setSortAsc] = useState(true);
  const [isLoading, setIsloading] = useState(null);


  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].localeCompare(a[name])}
      setSortAsc(false) ;
      return ( a[name].localeCompare(b[name]) ) 
    })
    setList({...list, [tab]:sorted});
  }

  const Th = (title,size,sort) => {
    return(
        <View style={{flex:size}}>
          <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
            <Text style={styles.item}>{title}</Text>
            <View>
                {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
            </View>
          </TouchableOpacity>
        </View>
    )
  }


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('ID',1,true)}
          {Th('Description',3)}
          <View style={{width:80}}><Text style={styles.item}>Amount</Text></View>
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const RemoveLoader = (id) => {
      if(id != isLoading) return null
      return (
        <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
          {Loader(true)}
        </View>
      )
    }

    const EditBtn  = (data) => {
      return (
        <TouchableOpacity style={[styles.option,{backgroundColor:'#aaa'}]} onPress={() => setModified(data)}>
        <Ionicons name="ios-pencil" size={24} color="#fff" />
      </TouchableOpacity>
      )
    }

    const specialList  = (data,link,color) => {
      if(!data) return null
      return (
        <TouchableOpacity style={[styles.option,{backgroundColor:color}]}  onPress={()=>window.open(link, '_blank')}>
           <Ionicons name="ios-list-circle" size={24} color="#fff" />
        </TouchableOpacity>
      )
    }

    const ExtraDetails = (year,data) => {
      
      return (
        <View >
          <View style={{flexWrap:'wrap',flexDirection:'row'}}>
              {specialList(data.claim,'credit/claims/'+data.claim,'#f3a')}
              {EditBtn(data)}
              {data.image?
                <View>
                    <TouchableOpacity style={[styles.option,{backgroundColor:'#00b0a4'}]} onPress={() => Linking.openURL(data.image)}>
                      <Ionicons name="ios-image-outline" size={24} color="#fff" />
                    </TouchableOpacity>
                    <Copy link={data.image} title={'Copy'}/>
                </View>
                :null
              }
              <TouchableOpacity style={[styles.option,{backgroundColor:'#ff3d5e'}]} onPress={()=> RemoveData(tab,data,year)}>
                <Ionicons name="trash" size={24} color="#fff" />
                <Text style={{color:'#fff'}}>delete</Text>
              </TouchableOpacity>
                
          </View>
        </View>
      )
    }

    const RenderItem = ({item,index}) => {

      const YearDir = moment(item.date.toDate()).format('YYYY')
      const Onclick = (item) =>{
        if(tab=='credit'){
          setOption(option == item.id?null:item.id)
        }else{
          nav.navigate('debit', {year:YearDir,bill: item.id})
        }
      }

      const Selected = option == item.id
      const Bgcolor = Selected?'#fff5d4': (index % 2 == 0  ? "#FFFFFF" : "#f2f2f2")

      return(
        <TouchableOpacity onPress={()=>Onclick(item)}>
          {RemoveLoader(item.id)}
          <View style={{flexDirection:'row',padding:5,paddingVertical:15, backgroundColor: Bgcolor }}>
              <View style={{flex:1}}>
                <Text style={styles.ids}>#{item.id}</Text>
                <Text style={styles.date}>{ReformatTime(item.date)}</Text>
              </View>
              <View style={{flex:3,paddingRight:5}}>
                {tab=='credit'?NameDetails(item):DebitDetails(item)}
                {option == item.id?ExtraDetails(YearDir,item):null}
              </View>
              <View style={{width:80}}><Text style={styles.price}>{numberWithCommas(parseFloat(item.amount).toFixed(2))}</Text></View>
          </View>
        </TouchableOpacity>
      )
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const NameDetails = (item) => {
    const name = item.category?item.category.name:item.name
      return (
        <View style={{flex:1,marginBottom:4}}>
          <Text style={styles.title}>{name}</Text>
          <View style={styles.flexcenter}>
            <Text style={styles.category}>{item.category.category}</Text>
            <Ionicons name="chevron-forward" size={10} style={{marginTop:4}} color="#ccc" />
            <Text style={styles.category}>{item.category.sub_category}</Text>
          </View>
        </View>
      )
  }

  const DebitDetails = (item) => {
      if(!item.name) return null
      return (
        <View>
          <Text style={[{color:!item.orderid && '#038399'}]}>{item.name}</Text>
          {item.orderid&&<Text style={styles.category}>Order id : {item.orderid}</Text>}
          {item.shopee_orders&&<Text style={styles.category}>total orders : {item.shopee_orders.length}</Text>}
        </View>
      )
  }



  async function RemoveData(tab,data,year){
    if (confirm('Are you sure you want to delete '+data.category.name+' ?')) {
      setIsloading(data.id)
      setOption(null)
      const filteredData = list[tab].filter(item => item.id !== data.id);
      setList({...list, [tab]:filteredData});
      const status = deleteCredit(data)
      console.log(await status)
      if(status){
        setIsloading(null)
      }
    } else {
      // Do nothing!
      setOption(null)
    }
  }

  function UpdateData(data){
    const dataArray = [ ...list['credit']]

    const newData = dataArray.map(({id,amount,date,image, ...others }) => id === data.id
    ? {id,amount:data.amount.toString(2),date:data.date,image:data.image, ...others }
    :{id,amount,date,image,...others}
    );
    setList({...list, ['credit']:newData})




  }

      const ReformatTime = (data) => {
        if(!data) return null
        const format = moment(data.toDate()).format('DD/MM/YY');
        return format
    }


    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 100 }}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            onEndReached={next}
          />
          <Edit data={modified} clear={()=>setModified(null)} update={(val)=>UpdateData(val)}/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    color:'#777',
    fontSize:14
  },
  price:{
    marginRight:5,
    color:'#777',
    fontSize:14,
    fontWeight:'500'
  },
  title:{
    color:'#777',
    fontSize:14,
  },
  ids:{
    color:'#aaa',
    fontSize:14
  },
  option:{
    borderRadius:5,
    padding:2,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
    paddingHorizontal:10,
    marginRight:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  category:{
    fontSize:14,
    color:'#479aca'
  },
  date:{
    fontSize:12,
    fontWeight:'200',
    color:'#ccc'
  }
});
