import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import moment from 'moment';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {UpdateItemStatus } from "../../assets/js/firebase";
import UpdateStaff from "./add";

export default function Table(props) {

  const {tab,setList,list,loading,nav} = props

  const [option, setOption] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [sortasc, setSortAsc] = useState(true);
  const [update, setUpdate] = useState(null);




  function Sortby(name,tab){
    var name = name.toLowerCase();
    const dataArray = [ ...list[tab]]
    var sorted = dataArray.sort((a,b) => {
      if(!sortasc){setSortAsc(true) ; return b[name].toString().localeCompare(a[name].toString())}
      setSortAsc(false) ;
      return ( a[name].toString().localeCompare(b[name].toString()) ) 
    })
    setList({...list, [tab]:sorted});
  }
  


const Th = (title,size,sort) => {
  return(
      <View style={{flex:size}}>
        <TouchableOpacity style={styles.flexcenter} onPress={()=>sort?Sortby(title,tab):null}>
          <Text style={styles.item}>{title}</Text>
          <View>
              {sort?<MaterialIcons name={sortasc?"arrow-drop-down":"arrow-drop-up"} size={20} color="#ccc" />:null}
          </View>
        </TouchableOpacity>
      </View>

  )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          <View style={{width:50}}/>
          {Th('Name',2)}
          {Th('Position',1)}
          {Th('Created',2,true)}
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }

    const UserIcon = () => {
      return (
        <View style={styles.user}>
          <Ionicons name="person" size={20} color="#ccc" />
        </View>
      )
    }

    const RemoveLoader = (id) => {
      if(id != isLoading) return null
      return (
        <View style={{position:'absolute',width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.8)',zIndex:22}}>
          {Loader(true)}
        </View>
      )
    }

    const RenderItem = ({item,index}) => {


      return(
        <View>
          {RemoveLoader(item.id)}
        <TouchableOpacity  onPress={()=>{option && item.id == option.id ? setOption(null): setOption(item)}} style={{padding:5,paddingVertical:15, backgroundColor: index % 2 == 0  ? "#FFFFFF" : "#f2f2f2" }}>
              <View style={{flexDirection:'row'}}>
                  <View style={{width:50}}><UserIcon/></View>
                  <View style={{flex:2}}>
                       <Text  style={[styles.title]}>{item.name}</Text>
                       <Text style={[styles.item]}>{item.mykad}</Text>
                       <Text style={[styles.item]}>staff : {item.id}</Text>
                  </View>
                  <View style={{flex:1}}><Text style={[styles.item]}>{item.position}</Text></View>
                  <View style={{flex:2}}><Text style={[styles.qty]}>{ReformatTime(item.createdAt)}</Text></View>
              </View>
              {option && option.id == item.id?Option(item):null}
        </TouchableOpacity>
        </View>
      )
  }

  const ReformatTime = (data) => {
    if(!data) return null
    const format = moment(data.toDate()).format('DD/MM/YY HH:MM A');
    return format
  }
  


  function UpdateStatus(data,stat){
    const status = stat?'active':'inactive'
    data['status'] = status
    const filteredData = list[tab].filter(item => item.id !== data.id);
    const dataArray = [ ...list[status]]
    dataArray.unshift(data)
    setList({...list, [tab]:filteredData,[status]:dataArray});
    UpdateItemStatus(data.id,status,'staff')
    setOption(null)
  }


  function Updatedata(data){
    const newData = list[tab].map((obj) => obj.id === data.id? data:obj);
    setList({...list, [tab]:newData})
  }




  const Option = (data) => {
    return (
      <View style={{flexDirection:'row',marginVertical:5}} >
          <View style={{flexDirection:'row',flex:1}}>
            {IconBtn('claims','Staff_Details',data,'receipt')}
            {IconBtn('payroll','Staff_Payroll',data,'cash')}
            <TouchableOpacity style={styles.option} onPress={()=> setUpdate(data)}>
                <Ionicons name={'pencil'} size={16} color='#4a8eb5' />
                <Text style={{color:'#4a8eb5',fontSize:14,marginLeft:2}}>edit</Text>
            </TouchableOpacity>
          </View>
          <UpdateStatusBtn status={data.status} data={data}/>
      </View>
    )
  }

  const IconBtn = (name,route,data,icon) => {
    return (
      <TouchableOpacity style={styles.option} onPress={()=> nav(route,data.id)}>
          <Ionicons name={icon} size={16} color='#4a8eb5' />
          <Text style={{color:'#4a8eb5',fontSize:14,marginLeft:2}}>{name}</Text>
      </TouchableOpacity>
    )
  }


  const UpdateStatusBtn = (props) => {
    const Status = props.status == 'inactive'?true:false
    return (
      <TouchableOpacity style={styles.option} onPress={()=> UpdateStatus(props.data,Status)}>
          <Ionicons name="remove-circle" size={16} color="#777" />
          <Text style={{color:'#4a8eb5',fontSize:14}}>{Status?'Activate':'Deactivate'}</Text>
      </TouchableOpacity>
    )
  }
    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <UpdateStaff updateData={update} clear={setUpdate} update={Updatedata}/>
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
          />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#4a8eb5',
  },
  desc:{
    color:'#777',
    fontWeight:'400'
  },
  qty:{
    color:'#aaa'
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    alignItems:'center',
    flexDirection:'row',
    backgroundColor:'#fff',
    borderRadius:5,
    padding:5,
    marginRight:5,
    shadowOffset: { width: 2, height: 2 },
        shadowRadius: 5,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  user:{
    borderWidth:1,
    padding:5,
    borderRadius:5,
    borderColor:'#ccc',
    width:30,
    alignContent:'center',
    alignItems:'center'
  }
});
