import React, { useState,useEffect,useRef } from 'react';
import { StyleSheet,View,TouchableOpacity } from 'react-native';
import {getAuth} from 'firebase/auth';
import Tab from './tab';
import Table from './table';
import SearchBox from "../../../assets/component/searchbox";
import {GetDelivers} from "../../../assets/js/firebase";


export default function ShipmentScreen({route,navigation}) {

  const auth = getAuth();
  const user = auth.currentUser;

  const LimitPerload = 12

  const isMounted = useRef();


  const DafaultTab = {pending:[],delivered:[]}


  const [list, setList] = useState(DafaultTab);
  const [tabactive, setTabActive] = useState(Object.keys(DafaultTab)[0]);
  const [isLoading, setIsloading] = useState(false);
  const [lastVisible , setLastVisible ] = useState(null);



  useEffect(() => {
      isMounted.current = true;
      if (isMounted.current) {
        if(list[tabactive].length == 0){
          setIsloading(true)
          getList(tabactive);
        }
      }
      return () => {
        isMounted.current = false;
      };
    }, [tabactive]);



  async function getList(active){

    const ListOrders = await GetDelivers('delivery',active,LimitPerload)
    if(isMounted.current){
      setLastVisible({...lastVisible, [active]:ListOrders.last});
      setList({...list, [active]:ListOrders.list});
      setIsloading(false)

    }

  };

  async function nextList(active,last){

      if(!last[active]) return null

      const ListOrders = await GetDelivers('delivery',active,LimitPerload,last[active])

      if(ListOrders){
        console.log("load More")
        setLastVisible({...lastVisible, [active]:ListOrders.last});

        const dataArray = [ ...list[active], ...ListOrders.list]
  
        setList({...list, [active]:dataArray});
        setIsloading(false)
      }
    
  };
  




  return (
    <View style={styles.container}>
      <View style={styles.tabBx}>
        <Tab active={tabactive} onPress={setTabActive} tab={DafaultTab} />
        {!isLoading &&<SearchBox set={(val)=>setList({...list, [tabactive]:val})} products={list[tabactive]} filters={['id','name','delivery']}/>}
      </View>
      <Table 
        tab={tabactive} 
        setList={setList} 
        list={list} 
        user={user}
        next={()=>nextList(tabactive,lastVisible)} 
        loading={isLoading}
        />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding:10,
    backgroundColor:'#fff'
  },
  tabBx:{
    flexDirection:'row',
    borderColor:'#ccc',
    marginVertical:10,
    flexWrap:'wrap'
  },
});
