import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert, ActivityIndicator,input,FlatList} from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import moment from 'moment';
import {getStorage,getDownloadURL ,ref,uploadBytesResumable,uploadString  } from "firebase/storage";
import Ionicons from '@expo/vector-icons/MaterialIcons';
import TextInput from "../../../assets/component/textinput";
import Modal from "../../../assets/component/modal";
import Button from "../../../assets/component/button";
import COLORS from "../../../assets/component/colors";
import DatePicker from "../../../assets/component/datepicker";
import {GETACC} from '../../../assets/js/getacc';
import {updateImageUrl,UpdateCredit,deleteCredit,AddCredit} from "../../../assets/js/firebase";


export default function Edit(props) {

    const {data,clear,update} = props

    const storage = getStorage();

    const [isLoading, setIsloading] = useState(false);

    const [amount, onAmount] = useState(0);
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const [dated, onDated] = useState("");
    const [error, setError] = useState("");

    const [percent, setPercent] = useState(0);



    useEffect(() => { 
        if(data){
            onAmount(data.amount)
            setImage(data.image)
            onDated(ReformatTime(data.date))
        }
    }, [data]);



    function handleChange(event) {
        setPercent(0)
        if (event.target.value.length == 0) return null

            if(event.target.files[0].type.split('/')[0] === 'image'){
                Compress(URL.createObjectURL(event.target.files[0])) 
            }else{
                setFile(event.target.files[0])
            }

    }

    const Compress = async (uri) => {
        const manipResult = await ImageManipulator.manipulateAsync(
            uri,
            [],
            { compress: 0.4 }
      );
      setFile(manipResult.uri)
    };



    async function handleUpload(year,data,type) {
        setIsloading(true)
        const user = await GETACC();

        var fileExt = file.name.split('.').pop();
        const storageRef = ref(storage,`/files/${user.id}/${type}/${data.id}.${fileExt}`)
        const uploadTask = uploadBytesResumable(storageRef, file);
     
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
     
                setPercent(percent);
            },
            (err) =>{ 
                alert("Exceed File Limit Size")
                console.log(err)
                setIsloading(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    updateImageUrl(type,year,data.id,url)
                    data['image'] = url
                    Success(data)
                });
            }
        ); 
    }


    
    async function handleUploadImage(year,data,type) {
        setIsloading(true)
        const user = await GETACC();
        const storageRef = ref(storage,`/files/${user.id}/${type}/${data.id}`)
        
        uploadString(storageRef, file, 'data_url').then((snapshot) => {

            getDownloadURL(storageRef).then((url) => {
                console.log(url)
                updateImageUrl(type,year,data.id,url)
                data['image'] = url
                Success(data)
            });
        },
        (err) =>{ 
                alert("Exceed File Limit Size")
                setIsloading(false)
        }); 
    }






    async function Validate(data){
        setError('')
        var valid = true;
        var Cat = null;


        if(!dated || dated.error){
            valid=false
        }

        if(amount <= 0 || isNaN(amount) || amount.error){
            onAmount({error:"Amount must not be 0"});
            valid=false
        }
   
        if(valid){
            setIsloading(true)

            var Increment = (parseFloat(amount) - parseFloat(data.amount))
            const YearDir = moment(data.date.toDate()).format('YYYY')
            const yearNow = moment(dated,'DD-MM-YYYY').format('YYYY')

            if(YearDir != yearNow){
                await deleteCredit(data) 
                data['amount'] = amount
                data['date'] = dated
                await AddCredit(data)
            }else{
                data['amount'] = amount
                data['date'] = dated
                await UpdateCredit(data,Increment)
            }

        
            if(file) {
                if(file.type){
                    handleUpload(yearNow,data,'credits')
                }else{
                    handleUploadImage(yearNow,data,'credits')
                }
            }else{
                Success(data)
            }
        }

    }

 
    function Success(data){
        setIsloading(false)
        update(data)
        onAmount("")
        setFile("")
        setPercent(0)
        clear()
    }



    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }

    const FileView = (name) => {
        return (
            <TouchableOpacity style={{flexDirection:'row',marginVertical:10}} onPress={()=>setImage(null)}>
                <Ionicons name='attach-file' size={22} color={'#106ac4'} />
                <View style={{flex:1,flexShrink:1,paddingHorizontal:5}}>
                    <Text>{name}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    const InputView = () => {
        return (
            <View style={{flexDirection:'row',marginVertical:10}}>
                <input type="file" onChange={handleChange} style={{flex:1}}/>
                {file?
                <TouchableOpacity onPress={()=> setFile(null)}>
                    <Ionicons name='close' size={18} color={COLORS.Error} />
                </TouchableOpacity>
                :null}
            </View>
        )
    }


    const ReformatTime = (data) => {
        if(!data) return null
        return moment(data.toDate()).format('DD-MM-YYYY');
    }



    const Popup = () => {
        if(!data) return null

        const amtEditted = amount == data.amount?null:'#1196ab'
        const dateEditted = dated == data.credited_date?null:'#1196ab'

        return (
                <Modal title={data.category.name} visible={data?true:false} setVisible={clear}>
                        <View >
                            <Text>{error}</Text>
                            <TextInput txtcolor={amtEditted} title={'Amount'} onChangeText={onAmount} value={amount} keyboardType="decimal-pad" maxLength={13} remark={"(RM)"}/>
                            <DatePicker set={onDated} value={dated} txtcolor={dateEditted}/>
                            {image?FileView(data.image):InputView()}
                        </View>
                        {!isLoading?<Button title={'UPDATE'} color={'#056a7a'} icon={'md-add-circle'} onPress={() => Validate(data)}/>:<ActivityIndicator size={'small'}/>}
                </Modal>
        )
    }
    


    return(
        <View>
                {Popup()}
        </View>
    )

}

const styles = StyleSheet.create({
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
  });
  