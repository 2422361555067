import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text} from 'react-native';
import TextInput from "../../assets/component/textinput";
import MultiInput from "../../assets/component/multiinput";
import ListDown from "../../assets/component/listdownoverlay";
import COLORS from "../../assets/component/colors";


export default function StaffForm(props) {

    const {data,set} = props

    const BankLIST = ['MAYBANK','CIMB','BANK ISLAM','AFFIN','ALLIANCE','AMBANK','BANK RAKYAT','HLB','HSBC','MUAMALAT','OCBC','PBB','RHB','UOB','BSN']



    const Employee = () => {
        return (
            <View>
                <View style={styles.coltitle}>
                    <Text style={styles.head}>Employee Deduction</Text>
                </View>
                {Columns([{name:'kwsp2',title:'KWSP'},{name:'socso2',title:'Sosco'},{name:'eis2',title:'EIS'},{name:'pcb',title:'PCB'}])}
                <MultiInput data={data['deduction_others']} add={(val)=>onChangeHandler(val,'deduction_others')}/>
            </View>
        )
    }

    const Employer = () => {
        return (
            <View>
                <View style={styles.coltitle}>
                    <Text style={styles.head}>Employer Contribution</Text>
                </View>
               {Columns([{name:'kwsp',title:'Kwsp'},{name:'socso',title:'Socso'},{name:'eis',title:'EIS'}])}
            </View>
        )
    }

    const Bank = () => {
        return (
            <View>
                <View style={styles.coltitle}>
                    <Text style={styles.head}>BANK</Text>
                </View>
                {ListVal('bank')}
                {InputVal('Account no','account')}
            </View>
        )
    }



    const Columns = (obj) => {
        const txtInputs = obj.map((val,i)=>{
            const right = i !== obj.length -1 && 10
            const sty = val.width?{width:val.width}:{flex:1}
                return <View key={i} style={[sty,{marginRight:right}]}>{InputVal(val.title,val.name,val.txt)}</View>}
            )
            
        return (
            <View style={{flexDirection:'row'}}>
                {txtInputs}
            </View>
        )
    }

    const InputVal = (title,val,txt) => {
        const inputVal = data && data[val] ? data[val]:''
        return (
                        <TextInput
                            title={title}
                            placeholder={title}
                            keyboardType={!txt&&'decimal-pad'} 
                            maxLength={13}
                            onChange={e => onChangeHandler(e,val)}
                            value={inputVal}
                            />
        )
    }

    const ListVal = (val) => {
        const inputVal = data && data[val] ? data[val]:BankLIST[0]
        return (
            <ListDown list={BankLIST} active={inputVal} onPress={e => onChangeHandler(e,val)}/>
        )
    }
    

    const onChangeHandler = (e,name) => {
        const Value = e.target?e.target.value:e
        set((prevState) => ({ ...prevState, [name]: Value }));
    };



        return (
                        <View style={styles.container} >
                                {Columns([{name:'name',title:'Name','txt':true},{name:'mykad',title:'Mykad',width:130}])}
                                {InputVal('Position','position',true)}
                                {InputVal('Basic Salary(RM)','basic')}
                                {InputVal('EPF NO','epf_no')}
                                {InputVal('PERKESO NO','perkeso_no')}
                                <MultiInput  data={data['income_others']} add={(val)=>onChangeHandler(val,'income_others')} category={['Allowance (Meal)','Allowance (Mobile)','OT','Parking']}/>
                                {Employer()}
                                {Employee()}
                                {Bank()}
                        </View>
        )


}

const styles = StyleSheet.create({
    container:{
        width:'100%',
        zIndex:33
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
      head:{
        fontWeight:'400',
        color:COLORS.Primary
      },
      coltitle:{
        borderBottomWidth:1,
        padding:5,
        borderColor:'#eee',
        marginVertical:5
      }
  });
  