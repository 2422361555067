import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';
import moment from 'moment'
import UpdateDriverModal from './update';


export default function Table(props) {

  const {tab,setList,list,next,loading} = props

  const [driver, setDriver] = useState(null);



  const Th = (title,size,center) => {
    return(
        <View style={{flex:size,alignItems:center && 'center'}}>
            <Text style={styles.item}>{title}</Text>
        </View>

    )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('Driver',3)}
          {Th('Status',1,true)}
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }



    const EcommerceIcon = (item) => {
      return (
        <View style={{flexDirection:'row',alignContent:'center',alignItems:'center'}}>
            {item.type == 'online_order' && <MaterialCommunityIcons name='laptop' size={14} color={'#aaa'} style={[styles.id,{marginRight:4}]}/>}
            <Text style={styles.id}>{item.id}</Text>
        </View>
      )
    }

    const CircleCount = (num,stat) => {
      const Bgcolor = ["delivered","pickup"].includes(stat) ? '#e1dbd2' : '#ffb94c'
      return (
        <View style={[styles.countbx,{backgroundColor:Bgcolor}]}>
          <Text style={styles.counttxt}>{num}</Text>
        </View>
      )
    }

    const Remark = (data) => {
      if(!data) return null
      return (

        <View style={{flexDirection:'row',alignContent:'center',alignItems:'center'}}>
            <Ionicons name='information-circle-outline' size={14} color={'#ff7c2a'} style={{marginRight:4}}/>
            <Text style={{color:'#ff7c2a',fontSize:12}}>{data}</Text>
         </View>
      )
    }

    const DeliveryAdd = (data) => {
      if(data.length > 80){
        data = '...'+data.substr(data.length - 80)
      }
      return (
        <Text style={[styles.item,{fontWeight:'200',fontSize:12}]}>{data}</Text>
      )
    }



    const RenderItem = ({item,index}) => {
      //#65d883
      const createdAt = moment(item.createdAt.toDate());
      const Days =  moment().diff(createdAt, 'days')
      const GetLastStatus = item.delivery_status.at(-1).status
      const StatusCount = item.delivery_status.length
      const BgColor =  Days > 5 ? '#ffe0d5' : index % 2 == 0  ? "#FFFFFF" : "#f2f2f2"
      return(
        <TouchableOpacity onPress={() => setDriver(item)}  style={{padding:5,paddingVertical:15, backgroundColor:BgColor }}>
          <View style={{flexDirection:'row'}}>
                  <View style={{flex:3}}>
                        <View style={{flexWrap:'wrap'}}>
                            <Text style={styles.title}>{item.name}</Text>
                            {item.company_transporter && <Text style={{color:'red'}}>in-house</Text>}
                            {DeliveryAdd(item.delivery)}
                            {Remark(item.remark)}
                            <Text style={[styles.date]}>{Days} Days</Text>
                            {EcommerceIcon(item)}
                        </View>
                  </View>
                  <View style={{flex:1,alignItems:'center'}}>
                    {CircleCount(StatusCount,GetLastStatus)}
                    <Text style={styles.item}>{GetLastStatus}</Text>
                  </View>
          </View>
        </TouchableOpacity>
      )
  }



  function UpdateData(data){
    const dataArray = [ ...list[tab]]
    const result = dataArray.map((obj) => obj.id === data.id ? data : obj);
    setList({...list, [tab]:result})
  }

  function CancelOrder(id){
    const filteredData = list[tab].filter(item => item.id !== id);
    setList({...list, [tab]:filteredData});
  }



    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            onEndReached={next}
          />
          <UpdateDriverModal data={driver} clear={setDriver} update={(val)=>UpdateData(val)} cancel={CancelOrder} />
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  id:{
    marginRight:5,
    color:'#006ad4',
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    position:'absolute',
    backgroundColor:'#fff',
    zIndex:1,
    left:-90,
    borderRadius:5,
    padding:10,
    paddingHorizontal:20,
    shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  counttxt:{
    fontSize:10,
    color:'#fff'
  },
  countbx:{
    width:15,
    height:15,
    borderRadius:10,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  date:{
    fontSize:12,
    color:'#03a9f4'
  }
});
