import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ActivityIndicator} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import Modal from "../../assets/component/modal";
import Button from "../../assets/component/button";
import COLORS from "../../assets/component/colors";
import {AddStaff,UpdateData} from "../../assets/js/firebase";
import Form from "./form";


export default function AddNew(props) {

    const {add,updateData,clear,visible,set,update} = props


    const [isLoading, setIsloading] = useState(false);
    const [data, onData] = useState({});
    const [error, setError] = useState("");

    useEffect(() => {
        if(updateData){
            onData(updateData)
        }
    }, [updateData]);




    async function Validate(){
        setError('')
        const valid = Validation(['name','mykad','position']);
        const validNum = ValidationNum(['basic','account']);
        const validcheckNum = CheckNum(['pcb','kwsp','kwsp2','eis','eis2','socso','socso2'])
        if(valid && validNum && validcheckNum){
            setIsloading(true)

            if(updateData){
                Update(data,data.id)
            }else{
                AddNew(data)
            }
        }else{
            setError("Fill All")
        }
    }

    async function Update(data) {
        const ID = data.id
        const AddStatus = await UpdateData('staff',ID,data);
        if(AddStatus){
            setIsloading(false)
            data['id'] = ID
            update(data)
            onData({})
            clear(null)
        }
    }

    async function AddNew(data) {
        const AddStatus = await AddStaff(data);
            if(AddStatus){
                setIsloading(false)
                add(AddStatus)
                onData({})
            }
    }
    

    function Validation(obj){
        var validated = true
        obj.map((name)=>{
            if(!data[name] || data[name].error){
                onData((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
                validated = false
            }
        })
        return validated
    }

    function ValidationNum(obj){
        var validated = true
        obj.map((name)=>{
            if(data[name] <= 0 || isNaN(data[name]) || data[name].error){
                onData((prevState) => ({ ...prevState, [name]: {error:"Only Integer Value"} }));
                validated = false
            }
        })
        return validated
    }

    function CheckNum(obj){
        var validated = true
        obj.map((name)=>{
            if(data[name] && isNaN(data[name])){
                onData((prevState) => ({ ...prevState, [name]: {error:"Only Integer Value"} }));
                validated = false
            }
        })
        return validated
    }





    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><Ionicons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }


    const Footer = () => {
        return (
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1}}><Error error={error}/></View>
                {!isLoading?<Button title={updateData?'UPDATE':'ADD'} icon={'md-add-circle'} onPress={() => Validate()}/>:<ActivityIndicator size={'small'}/>}
            </View>
        )
    }



    if(updateData){
        return (
                <Modal title={'UPDATE STAFF'} icon={'person'} visible={true} setVisible={clear} footer={Footer()}>
                    <Form data={data} set={onData}/>
                </Modal>
        )
    }
    
    

    return (
        <Modal title={'ADD STAFF'} icon={'person'} visible={visible} setVisible={set} footer={Footer()}>
            <Form data={data} set={onData}/>
        </Modal>
    )


}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        flexGrow:1,
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      dualL:{
        flex:1,
        marginRight:5
      },
      dualR:{
        flex:1,
        marginLeft:5
      },
      head:{
        fontWeight:'400',
        color:COLORS.Primary
      },
      coltitle:{
        borderBottomWidth:1,
        padding:5,
        borderColor:'#eee',
        marginVertical:5
      }
  });
  